import {Skeleton} from 'antd';
import {lazy, Suspense} from 'react';

import {RequestRecordViewProps} from '../overlays/request-view/RequestView';
import {NotificationProvider} from '../utils/notification/useNotification';
import {DynamicTableBlockParams} from './DynamicTableBlock';
import {DynamicTableeWithTabsParams} from './DynamicTablesWithTabs';

export const DynamicTableBlockID = 'urn:sfblock:dynamic-components:dynamic-tables-content';

const LazyDynamicTablesWithTabsDevelopment = lazy(() => import('./DynamicTablesWithTabs'));
export const AsyncLazyDynamicTablesWithTabsDevelopment = (props: DynamicTableeWithTabsParams) => (
    <Suspense fallback={<Skeleton />}>
        <LazyDynamicTablesWithTabsDevelopment {...props} />
    </Suspense>
);

const LazyRequestViewDrawer = lazy(() => import('../overlays/request-view/RequestView'));

const LazyRequestClientViewDrawer = lazy(() => import('../overlays/client-view/ClientView'));

export const AsyncLazyRecordViewDrawer = (props: RequestRecordViewProps) => (
    <Suspense fallback={<Skeleton />}>
        <NotificationProvider>
            <LazyRequestViewDrawer {...props} />
        </NotificationProvider>
    </Suspense>
);

export const AsyncLazyClientViewDrawer = (props: RequestRecordViewProps) => (
    <Suspense fallback={<Skeleton />}>
        <NotificationProvider>
            <LazyRequestClientViewDrawer {...props} />
        </NotificationProvider>
    </Suspense>
);

const LazyDynamicTable = lazy(() => import('./DynamicTableBlock'));
export const AsyncLazyDynamicTable = (props: DynamicTableBlockParams) => (
    <Suspense fallback={<Skeleton />}>
        <LazyDynamicTable {...props} />
    </Suspense>
);
